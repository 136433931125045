import "./App.css";
import Details from "./Details/Details";
import Home from "./Home/Home";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/item/:id" element={<Details />} />
      </Routes>
    </Router>
  );
}
