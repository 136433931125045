import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const SliderCarousel = ({images}) => {
  return (
    <Carousel  >

        {
            images.map((item,key)=>{

                return <div key={key}>
                <img src={item} />
               {//<p className="legend">Legend 1</p>
               } 
              </div>
            })
        }
      
   </Carousel>
  );
};
export default SliderCarousel