import arrowOne from '../Assets/arrow-one.svg'
import arrowTwo from '../Assets/arrow-two.svg'
import arrowThree from '../Assets/arrow-three.svg'
import { Link } from 'react-router-dom';

export default function Blocks({type}){

    const colors=[
        '#BE8528',
        '#0BA5C5',
        '#EA4C39'
    ]

    const services=[
        'المراكز الاسرية',
        'المراكز السوسيو طبية',
        'المراكز الاجتماعية'
    ]

    const arrow=(type==2)?arrowOne:((type==1)?arrowTwo:arrowThree)

    const classNameArrow=(type==2)?'arrowOne':((type==1)?'arrowTwo':'arrowThree')
    const classNameFloating=(type==2)?'floatingOne':((type==1)?'floatingTwo':'floatingThree')

    const mainColor=colors[type]

    const colorStyle={

        color:mainColor
    }

    const bgcolorStyle={

        backgroundColor:mainColor
    }

    const addFloating=(e)=>{

        const el=e.target.nextElementSibling;

        const img=el;

        img.classList.add(classNameFloating)

    }

    const removeFloating=(e)=>{

        const el=e.target.nextElementSibling;

        const img=el;

        img.classList.remove(classNameFloating)

    }

    return <>
  
   <div className='blocks relative'>
        <div className='gissrColor fsM'>جسر </div>
        <div style={colorStyle} className='fsM'>{services[type]}</div>
       <Link to={'/item/'+type} > <button style={bgcolorStyle} className='Blocksbtn fsM'  onMouseMove={addFloating} onMouseLeave={removeFloating} >  اكتشف خدماتنا</button>
       <img src={arrow}  className= {classNameArrow+' absolute '} />
       </Link>

       
    </div>

   

    </>
}