import logoGissr from '../Assets/logo-gissr.svg'
import discoursSm from '../Assets/discours-sm.svg'
import bg from '../Assets/bg.svg'
import bridge from '../Assets/bridge.svg'
import Blocks from '../Blocks/Blocks'
export default function Home(){


    const bgStyle={

        backgroundImage: 'url('+bg+')',
        backgroundSize:'cover',
        height:'-webkit-fill-available'
    }

    const bridgeStyle={

        backgroundImage: 'url('+bridge+')',
        backgroundSize:'100% 100%',
        backgroundPosition:'center',
       // height:'400px',
       height: 'Calc(100vh - 297px)',
        margin:'0px 10%',
        border:'2px solid #DDC795',
        borderRadius:'12px 12px 0px 0px',
       // position: 'absolute',
       // bottom: '-26px',
        width: '80%'
    }

    return <>
    
    <div style={bgStyle}>
    <div className="flex flex-row justify-center p-4">
      
        {//<div ><img  src={discoursSm} style={{'height':150}}/></div>
        }
        <div ><img  src={logoGissr} width={150}/></div>
    </div>
    <div className='text-center'>
        <h1 className='gissrStrategy'>استراتيجية جسر </h1>
        <h2 className='gissrUnderline'>نحو تنمية اجتماعية شاملة  مبتكرة مستدامة</h2>
    </div>

    <div className='relative mt-3'>
     

        <div className='flex flex-row justify-between absolute z-[9999] w-full blocksParent'>
        <Blocks type={0} />
        <Blocks type={1} />
        <Blocks type={2} />
        </div>
        <div style={bridgeStyle} className=''>
        
        </div>

    </div>
    </div>
    </>
}