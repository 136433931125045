import React, { useRef, useState } from "react";
import data from "./AccordionData";
import { RiArrowDropDownLine } from "react-icons/ri";

//  accordionitem component
const AccordionItem = ({ question, answer, isOpen, onClick, color }) => {
  const contentHeight = useRef();
  return (
    <div className="wrapper">
      <button
        className={`question-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <div className="flex flex-row gap-[12px] items-center">
        <svg width="45"  viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2009_1175)">
            <path d="M10.6665 11.6458H26.6665V32.9791H10.6665V11.6458Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6665 43.6458H26.6665V54.3124H10.6665V43.6458Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.3335 32.979H53.3335V54.3123H37.3335V32.979Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.3335 11.6458H53.3335V22.3124H37.3335V11.6458Z" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_2009_1175">
              <rect width="64" height="64" fill="white" transform="translate(0 0.979004)" />
            </clipPath>
          </defs>
        </svg>

        <p className="question-content fsM" style={{ color: color }}>{question}</p>
        </div>
       
        {isOpen &&

          <svg width="20" className="ml-4" viewBox="0 0 39 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.29074 21.6512C0.459077 20.8195 0.459077 19.4711 1.29074 18.6395L18.3275 1.60266C19.1592 0.771002 20.5076 0.771002 21.3392 1.60266L38.376 18.6395C39.2077 19.4711 39.2077 20.8195 38.376 21.6512C37.5444 22.4828 36.196 22.4828 35.3643 21.6512L19.8334 6.12022L4.30244 21.6512C3.47078 22.4828 2.1224 22.4828 1.29074 21.6512Z" fill="#475569" fillOpacity="0.52" />
          </svg>

        }{!isOpen &&

          <svg width="20" className="ml-4" viewBox="0 0 39 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.3763 1.60275C39.2079 2.43441 39.2079 3.7828 38.3763 4.61446L21.3395 21.6512C20.5078 22.4829 19.1594 22.4829 18.3278 21.6512L1.29097 4.61445C0.459312 3.78279 0.459312 2.43441 1.29097 1.60275C2.12263 0.771087 3.47102 0.771087 4.30268 1.60275L19.8336 17.1337L35.3645 1.60275C36.1962 0.771089 37.5446 0.771089 38.3763 1.60275Z" fill="#475569" fillOpacity="0.52" />
          </svg>
        }
      </button>

      <div
        ref={contentHeight}
        className="answer-container"
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <ul className="p-3 mb-3 fsM">{answer}</ul>
      </div>
    </div>
  );
};

const Accordion = ({ services, id, color }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const serviceData = services[id].services;

  return (
    <div >
      {serviceData.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.name}
          answer={item.subservices.map(((it,k) => {

            return <li key={k} className="customBulletOne">{it}</li>
          }))}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
          color={(item.color) ? item.color : color}
        />
      ))}
    </div>
  );
};

export default Accordion;
