import React from 'react';

export default function Popup({url, closePopup, text}){

 
        const platforme = "<iframe width='100%' height='100%' scrolling='yes' src='"+url+"/dashboard' sandbox='allow-top-navigation allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>";

        return(
            <div className='popup'>
                <div className='popup_inner'>
                   
                <iframe 
          width='100%' 
          height='100%' 
          src={`${url}/dashboard`} 
          sandbox='allow-top-navigation allow-modals allow-forms allow-popups allow-scripts allow-same-origin'
          title='Popup Iframe'
        />                    <button onClick={closePopup} className='fixed z-[99999999] top-12 right-12'> <svg width="40"  viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.251051 0.251051C0.585787 -0.0836838 1.1285 -0.0836838 1.46323 0.251051L6 4.78782L10.5368 0.251052C10.8715 -0.0836833 11.4142 -0.0836833 11.7489 0.251052C12.0837 0.585787 12.0837 1.1285 11.7489 1.46323L7.21218 6L11.7489 10.5368C12.0837 10.8715 12.0837 11.4142 11.7489 11.7489C11.4142 12.0837 10.8715 12.0837 10.5368 11.7489L6 7.21218L1.46323 11.7489C1.1285 12.0837 0.585786 12.0837 0.251051 11.7489C-0.0836838 11.4142 -0.0836838 10.8715 0.251051 10.5368L4.78782 6L0.251051 1.46323C-0.0836838 1.1285 -0.0836838 0.585786 0.251051 0.251051Z" fill="#50799E"/>
</svg>
 </button>
                </div>
            </div>
        );
    
}

